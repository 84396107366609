var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4","lg":"4","xl":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{},[_c('h2',[_vm._v("In App Purchase Transactions")])])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2","lg":"2","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.TransactionsItemsNew,"dense":"","label":"Sort By Pack Selected","outlined":"","item-text":"pack_name","item-value":"pack_name"},model:{value:(_vm.TransactionsStatus),callback:function ($$v) {_vm.TransactionsStatus=$$v},expression:"TransactionsStatus"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","lg":"3","xl":"3"}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"mr-2 mt-5",attrs:{"append-icon":"mdi-magnify","label":"search","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.TransactionsData,"items":_vm.TransactionsItems,"dense":"","fixed-header":_vm.fixed,"height":_vm.height,"search":_vm.search,"items-per-page":100,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No Transactions recorded yet ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.transaction_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transaction_on != null ? _vm.get_date(item.transaction_on) : "N/A"))])]}},{key:"item.organisation_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.organisation_name != null && item.organisation_name != undefined ? item.organisation_name : "N/A")+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.organisation_name != null && item.organisation_name != undefined ? item.organisation_name : "N/A")+" ")])])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.comment != null && item.comment != undefined ? item.comment : "N/A")+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.comment != null && item.comment != undefined ? item.comment : "N/A")+" ")])])]}},{key:"item.s3_key",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.s3_key != null || item.s3_key != undefined)?_c('a',_vm._g({on:{"click":function($event){return _vm.download_invoice(
                    ("https://prznceinvoice.s3.ap-south-1.amazonaws.com/" + (item.s3_key))
                  )}}},on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-download ")])],1):_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("mdi-cloud-off-outline")])]}}],null,true)},[(item.s3_key != null || item.s3_key != undefined)?_c('span',{staticClass:"white--text"},[_vm._v("Download Invoice")]):_c('span',{staticClass:"white--text"},[_vm._v("Invoice Not Available")])])]}}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }