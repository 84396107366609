<template>
  <div>
    <v-card flat>
      <v-toolbar flat class="mt-1">
        <div class="mr-5 mt-4"><h3>Reports</h3></div>
        <v-dialog
          ref="dialog1"
          v-model="modal"
          color="orange"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              dense
              outlined
              class="mt-6"
              persistent-hint
              label="From Date"
              color="orange"
              append-icon="mdi-calendar"
              style="max-width: 150px"
              @click:append="date ? getcal() : getcal()"
              v-bind="attrs"
              v-on="on"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            :max="new Date().toISOString().substr(0, 10)"
            v-model="date"
            color="orange"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="orange" @click="modal = false"> Cancel </v-btn>
            <v-btn
              v-on:click="get_scanlogs_pagination()"
              text
              color="orange"
              @click="$refs.dialog1.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-dialog
          ref="dialog"
          v-model="endmodal"
          color="orange"
          :return-value.sync="todate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataFormatted"
              dense
              outlined
              class="mt-6 ml-2"
              persistent-hint
              label="To Date"
              color="orange"
              append-icon="mdi-calendar"
              style="max-width: 150px"
              @click:append="todate ? gettimecal() : gettimecal()"
              v-bind="attrs"
              v-on="on"
              readonly
            >
            </v-text-field>
          </template>
          <v-date-picker
            :max="new Date().toISOString().substr(0, 10)"
            :min="date"
            v-model="todate"
            color="orange"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="orange" @click="endmodal = false">
              Cancel
            </v-btn>
            <v-btn
              v-on:click="get_scanlogs_pagination()"
              text
              color="orange"
              @click="$refs.dialog.save(todate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <div class="ml-4"><b> Total Amount : </b>{{ total_amount }}</div>
        <div class="ml-4"><b>Total Credits : </b>{{ total_credits }}</div>
        <div class="ml-4"><b> Cost/Credit : </b>{{ total_average }}</div>

        <v-spacer />
        <v-btn
          depressed
          @click="Exportreport()"
          dark
          :loading="buttonload"
          class="gradient-bg white--text"
        >
          Export
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :height="height"
              :fixed-header="fixed"
              :headers="headers"
              :items="tableData"
              :loading="tableLoading"
              :items-per-page="10"
              dense
              class="elevation-0"
            >
              <template v-slot:[`item.transaction_on`]="{ item }">
                <span>{{ fetch_value(item.transaction_on) }}</span>
              </template>
              <!-- <template v-slot:[`item.report_year`]="{ item }">
                <span>{{
                  month_year(item.report_year, item.report_month)
                }}</span>
              </template> -->
              <template v-slot:[`item.export_report_sts`]="{ item }">
                <span v-if="item.export_report_sts == 'COMPLETED'">
                  <v-btn small text class="white--text ml-n3" color="green"
                    >COMPLETED</v-btn
                  >
                </span>
                <span v-if="item.export_report_sts == 'IN PROGRESS'">
                  <v-btn small text class="white--text ml-n3" color="orange"
                    >INPROGRESS</v-btn
                  >
                </span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <span v-if="item.export_report_sts == 'COMPLETED'">
                  <v-icon
                    class=""
                    @click="download_invoice(`${item.report_url}`)"
                    color="orange"
                    >mdi-download</v-icon
                  ></span
                >
                <span v-else> - </span>
              </template>
            </v-data-table>
          </v-col>
          <!-- <v-col cols="6">
            <v-card :height="cardheight" class="">
              <v-container class="fill-height" fluid>
                <Bar :data="data_graph" :options="options" />
              </v-container>
            </v-card> </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <div v-if="compenetCheck == 1">
      <ExpenseGenerate
        :reportexpense="reportexpense"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
        @clicked="reportexpense = false"
      />
    </div>
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import { generate_transactions_reports } from "@/graphql/mutations.js";
import { list_transactions_reports } from "@/graphql/queries.js";
import ExpenseGenerate from "@/components/Dialogs/ExpenseGenerate.vue";
import { API, graphqlOperation } from "aws-amplify";
import SnackBar from "@/components/SnackBar.vue";
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale,
// } from "chart.js";
import { Bar } from "vue-chartjs";
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );
const FileSaver = require("file-saver");
import axios from "axios";
export default {
  components: {
    Bar,
    SnackBar,
    ExpenseGenerate,
    Overlay,
  },
  data() {
    return {
      height: 0,
      overlay: false,
      SnackBarComponent: {},
      compenetCheck: 0,
      total_amount: "",
      total_average: "",
      total_credits: "",
      fixed: true,
      modal: false,
      endmodal: false,
      tableLoading: false,
      reportexpense: false,
      buttonload: false,
      date: new Date().toISOString().substr(0, 10),
      todate: new Date().toISOString().substr(0, 10),
      tableData: [],
      options: {
        indexAxis: "x",
        width: 260,
        scales: {
          x: {
            stacked: true,
            barPercentage: 0.1,
            grid: {
              display: false,
              width: 20,
            },

            ticks: {
              precision: 0,
            },
          },
          y: {
            stacked: true,
            barPercentage: 0.1,
            grid: {
              display: true,
            },
          },
        },

        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "",
          },
        },
      },
      data_graph: {
        labels: ["January", "February", "March"],
        // labels: [],
        datasets: [
          {
            backgroundColor: ["rgb(179, 230, 255)"],
            borderColor: "rgb(0, 136, 204)",

            borderWidth: 2,
            borderSkipped: false,
            data: [68, 85, 89],
            // borderWidth: 1,
            // barPercentage: 0.5,
            // barThickness: 20,
            // maxBarThickness: 50,
            // minBarLength: 56,
          },
        ],
      },
      headers: [
        { text: "Organization Name", value: "organisation_name" },
        { text: "Amount Paid", value: "amount" },
        { text: "Credits Added", value: "credits_to_be_added" },
        { text: "Pack Selected", value: "pack_name" },
        { text: "Paid via", value: "payment_gateway" },
        { text: "Transacted On", value: "transaction_on" },
        // { text: "Comments", value: "comment" },
        // { text: "Invoice", value: "s3_key" },
      ],
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    dataFormatted() {
      return this.dateformating(this.todate);
    },
  },
  async created() {
    this.height = window.innerHeight - 240;
    this.cardheight = window.innerHeight - 220;
    // await this.list_reports();
    await this.list_get();
  },
  methods: {
    generatereport() {
      this.compenetCheck = 1;
      this.reportexpense = true;
    },
    fetch_value(val) {
      const d = new Date(parseInt(val * 1000));
      var date = d.getDate().toLocaleString();
      var month = (d.getMonth() + 1).toLocaleString();
      var year = d.getFullYear();

      return (
        (+date <= 9 ? "0" + date : date) +
        "/" +
        (+month <= 9 ? "0" + month : month) +
        "/" +
        year
      );
    },
    async list_reports() {
      this.tableLoading = true;

      try {
        let result = await API.graphql(
          graphqlOperation(list_transactions_reports, {
            input: {
              limit: 100,
              nextToken: null,
              start_time: this.date,
              end_time: this.todate,
              action_type: "LIST",
            },
          })
        );
        this.tableLoading = false;
        // console.log(result);
        var response = JSON.parse(result.data.list_transactions_reports);
        if (response.Status == "SUCCESS") {
          this.tableData = response.data;
        }
        // console.log(response.data);
      } catch (error) {
        this.tableLoading = false;

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
          timeout: 5000,
          Top: true,
        };
      }
    },
    async list_get() {
      this.tableLoading = true;
      this.overlay = true;
      this.tableData = [];
      this.total_amount = "";
      this.total_credits = "";
      this.total_average = "";
      try {
        let result = await API.graphql(
          graphqlOperation(list_transactions_reports, {
            input: {
              start_time: this.date,
              end_time: this.todate,
              action_type: "TOTAL_AMOUNT",
              limit: 100,
              nextToken: null,
            },
          })
        );
        this.tableLoading = false;
        this.overlay = false;
        // console.log(JSON.parse(result.data.list_transactions_reports));
        if (JSON.parse(result.data.list_transactions_reports) != null) {
          var response = JSON.parse(result.data.list_transactions_reports);
          if (response.Status == "SUCCESS") {
            this.overlay = false;
            this.total_amount = response.total_amount;
            this.total_credits = response.total_credits;
            this.total_average = response.avrg;
            this.tableData = response.data;
            // console.log(response);
          }
        } else {
          this.tableData = [];
          this.total_amount = "N/A";
          this.total_credits = "N/A";
          this.overlay = false;
          this.total_average = "N/A";
        }
        // console.log(response.data);
      } catch (error) {
        this.tableLoading = false;
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
          timeout: 5000,
          Top: true,
        };
      }
    },
    get_scanlogs_pagination() {
      this.list_get();
    },
    dateformating(todate) {
      if (!todate) return null;
      const [year, month, day] = todate.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    get_next() {
      // console.log();
    },
    // generatereport() {
    //   console.log(this.date, this.todate);
    // },
    getcal() {
      this.modal = true;
    },
    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };

      this.reportexpense = false;

      this.list_reports();
    },

    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
    download_invoice(item) {
      window.open(item);
      // console.log(item);
    },
    async download_item(item) {
      var fileName = item.report_name + ".xlsx";
      axios({
        url: item.report_url,
        method: "GET",
        responseType: "blob", // Set the response type to blob
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // Use the FileSaver library to save the file
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    },
    download_invoice(item) {
      window.open(item);
      // console.log(item);
    },
    async Exportreport() {
      this.buttonload = true;

      try {
        let result = await API.graphql(
          graphqlOperation(generate_transactions_reports, {
            input: {
              start_time: this.date,
              end_time: this.todate,
              user_email_id: this.$store.getters.get_useremail,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.generate_transactions_reports);
        this.buttonload = false;
        if (response.Status == "SUCCESS") {
          this.download_invoice(response.url);
          // console.log(response.url);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            SnackbarText: response.Message,
            timeout: 5000,
            Top: true,
          };
          // this.list_reports();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: response.Message,
            timeout: 5000,
            Top: true,
          };
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.errors[0].message,
          timeout: 5000,
          Top: true,
        };

        this.buttonload = false;
      }
    },
  },
};
</script>

<style>
</style>