var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"mt-1",attrs:{"flat":""}},[_c('div',{staticClass:"mr-5 mt-4"},[_c('h3',[_vm._v("Reports")])]),_c('v-dialog',{ref:"dialog1",attrs:{"color":"orange","return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6",staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","persistent-hint":"","label":"From Date","color":"orange","append-icon":"mdi-calendar","readonly":""},on:{"click:append":function($event){_vm.date ? _vm.getcal() : _vm.getcal()}},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().substr(0, 10),"color":"orange","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"orange"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"orange"},on:{"click":[function($event){return _vm.get_scanlogs_pagination()},function($event){return _vm.$refs.dialog1.save(_vm.date)}]}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"color":"orange","return-value":_vm.todate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.todate=$event},"update:return-value":function($event){_vm.todate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6 ml-2",staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","persistent-hint":"","label":"To Date","color":"orange","append-icon":"mdi-calendar","readonly":""},on:{"click:append":function($event){_vm.todate ? _vm.gettimecal() : _vm.gettimecal()}},model:{value:(_vm.dataFormatted),callback:function ($$v) {_vm.dataFormatted=$$v},expression:"dataFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endmodal),callback:function ($$v) {_vm.endmodal=$$v},expression:"endmodal"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().substr(0, 10),"min":_vm.date,"color":"orange","scrollable":""},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"orange"},on:{"click":function($event){_vm.endmodal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"orange"},on:{"click":[function($event){return _vm.get_scanlogs_pagination()},function($event){return _vm.$refs.dialog.save(_vm.todate)}]}},[_vm._v(" OK ")])],1)],1),_c('div',{staticClass:"ml-4"},[_c('b',[_vm._v(" Total Amount : ")]),_vm._v(_vm._s(_vm.total_amount))]),_c('div',{staticClass:"ml-4"},[_c('b',[_vm._v("Total Credits : ")]),_vm._v(_vm._s(_vm.total_credits))]),_c('div',{staticClass:"ml-4"},[_c('b',[_vm._v(" Cost/Credit : ")]),_vm._v(_vm._s(_vm.total_average))]),_c('v-spacer'),_c('v-btn',{staticClass:"gradient-bg white--text",attrs:{"depressed":"","dark":"","loading":_vm.buttonload},on:{"click":function($event){return _vm.Exportreport()}}},[_vm._v(" Export ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"height":_vm.height,"fixed-header":_vm.fixed,"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.tableLoading,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"item.transaction_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.fetch_value(item.transaction_on)))])]}},{key:"item.export_report_sts",fn:function(ref){
var item = ref.item;
return [(item.export_report_sts == 'COMPLETED')?_c('span',[_c('v-btn',{staticClass:"white--text ml-n3",attrs:{"small":"","text":"","color":"green"}},[_vm._v("COMPLETED")])],1):_vm._e(),(item.export_report_sts == 'IN PROGRESS')?_c('span',[_c('v-btn',{staticClass:"white--text ml-n3",attrs:{"small":"","text":"","color":"orange"}},[_vm._v("INPROGRESS")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.export_report_sts == 'COMPLETED')?_c('span',[_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.download_invoice(("" + (item.report_url)))}}},[_vm._v("mdi-download")])],1):_c('span',[_vm._v(" - ")])]}}],null,true)})],1)],1)],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),(_vm.compenetCheck == 1)?_c('div',[_c('ExpenseGenerate',{attrs:{"reportexpense":_vm.reportexpense},on:{"errorMsg":_vm.error_info,"successMsg":_vm.success_info,"clicked":function($event){_vm.reportexpense = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }