<template>
  <!-- eslint-disable -->
  <v-dialog v-model="reportexpense" persistent max-width="400">
    <v-card>
      <v-toolbar elevation="0" dark dense class="gradient-bg elevation-0 white--text">
        <v-toolbar-title class="text--white ml-2"
          >Generate Expense Report</v-toolbar-title
        >
        <v-spacer />
        <v-icon @click="close_dialog()">mdi-close</v-icon>
      </v-toolbar>

      <v-card-text class="mt-4">
        <v-form ref="form">
          <v-dialog
            ref="dialog1"
            v-model="modal"
            color="orange"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                dense
                outlined
                class="mt-6"
                persistent-hint
                label="From Date"
                color="orange"
                append-icon="mdi-calendar"
                @click:append="date ? getcal() : getcal()"
                v-bind="attrs"
                v-on="on"
                readonly
              >
              </v-text-field>
            </template>
            <v-date-picker
              :max="new Date().toISOString().substr(0, 10)"
              v-model="date"
              color="orange"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="orange" @click="modal = false"> Cancel </v-btn>
              <v-btn
                v-on:click="get_scanlogs_pagination()"
                text
                color="orange"
                @click="$refs.dialog1.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-dialog
            ref="dialog"
            v-model="endmodal"
            color="orange"
            :return-value.sync="todate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatted"
                dense
                outlined
                class="mt-6 "
                persistent-hint
                label="To Date"
                color="orange"
                append-icon="mdi-calendar"
                @click:append="todate ? gettimecal() : gettimecal()"
                v-bind="attrs"
                v-on="on"
                readonly
              >
              </v-text-field>
            </template>
            <v-date-picker
              :max="new Date().toISOString().substr(0, 10)"
              :min="date"
              v-model="todate"
              color="orange"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="orange" @click="endmodal = false">
                Cancel
              </v-btn>
              <v-btn
                v-on:click="get_scanlogs_pagination()"
                text
                color="orange"
                @click="$refs.dialog.save(todate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          depressed
          :loading="buttonload"
          @click="generatereport()"
          dark
          color="primary"
          class="white--text"
        >
          Generate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
      
      <script>
import { generate_transactions_reports } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    reportexpense: Boolean,
  },
  data() {
    return {
      buttonload: false,
      modal: false,
      endmodal: false,
      date: new Date().toISOString().substr(0, 10),
      todate: new Date().toISOString().substr(0, 10),
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    dataFormatted() {
      return this.dateformating(this.todate);
    },
  },
  watch: {
    reportexpense: {
      async handler() {
        if (this.reportexpense == true) {
        }
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
    dateformating(todate) {
      if (!todate) return null;
      const [year, month, day] = todate.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getcal() {
      this.modal = true;
    },
    async generatereport() {
      if (this.$refs.form.validate()) {
        var data = this.$store.getters.GetUserObj;
        this.buttonload = true;

        try {
          let result = await API.graphql(
            graphqlOperation(generate_transactions_reports, {
              input: {
                start_time: this.date,
                end_time: this.todate,
                user_email_id: this.$store.getters.get_useremail,
              },
            })
          );
          // console.log(result);
          var response = JSON.parse(result.data.generate_transactions_reports);
          this.buttonload = false;
          if (response.Status == "SUCCESS") {
            this.$emit("successMsg", response.Message);
          } else {
            this.$emit("errorMsg", response.Message);
          }
        } catch (error) {
          this.$emit("errorMsg", error.errors[0].message);
          this.buttonload = false;
        }
      }
    },
  },
};
</script>
      
      <style>
</style>