<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-toolbar dense elevation="0">
          <div class="mr-5"><h3>Promotional Transactions</h3></div>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <!-- <v-col cols="12" md="2" class="mr-2">
        <v-select
          v-model="PaidStatus"
          :items="PaidItems"
          dense
          outlined
          label="Sort by Paid Via"
          item-text="payment_gateway"
          item-value="payment_gateway"
        >
        </v-select>
      </v-col> -->
      <!-- <v-col cols="12" md="2">
        <v-select
          v-model="TransactionsStatus"
          :items="TransactionsStatusItems"
          dense
          label="Sort by Pack Selected"
          outlined
          item-text="pack_name"
          item-value="pack_name"
        >
        </v-select>
      </v-col> -->
      <v-col cols="12" md="3">
        <v-toolbar dense class="elevation-0">
          <v-text-field
            v-model="search"
            label="search"
            class="mr-2 mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12">
        <v-data-table
          :headers="TransactionsData"
          :items="TransactionsItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :items-per-page="100"
          :loading="isLoading"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Transactions recorded yet
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.transaction_on`]="{ item }">
            <span>{{
              item.transaction_on != null
                ? get_date(item.transaction_on)
                : "N/A"
            }}</span>
          </template>
          <template v-slot:[`item.organisation_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.organisation_name != null &&
                    item.organisation_name != undefined
                      ? item.organisation_name
                      : "N/A"
                  }}
                </span>
              </template>
              <span class="white--text"
                >{{
                  item.organisation_name != null &&
                  item.organisation_name != undefined
                    ? item.organisation_name
                    : "N/A"
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.comment`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.comment != null && item.comment != undefined
                      ? item.comment
                      : "N/A"
                  }}
                </span>
              </template>
              <span class="white--text"
                >{{
                  item.comment != null && item.comment != undefined
                    ? item.comment
                    : "N/A"
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.s3_key`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  v-if="item.s3_key != null || item.s3_key != undefined"
                  @click="
                    download_invoice(
                      `https://prznceinvoice.s3.ap-south-1.amazonaws.com/${item.s3_key}`
                    )
                  "
                >
                  <v-icon small color="primary">mdi-download </v-icon>
                </a>
                <v-icon v-else small color="grey">mdi-cloud-off-outline</v-icon>
              </template>
              <span
                v-if="item.s3_key != null || item.s3_key != undefined"
                class="white--text"
                >Download Invoice</span
              >
              <span v-else class="white--text">Invoice Not Available</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllTransactionsDetails } from "@/graphql/queries.js";

export default {
  components: {
    Overlay,
    SnackBar,
  },
  data: () => ({
    TransactionsData: [
      { text: "Organization Name", value: "organisation_name" },
      // { text: "Amount Paid", value: "amount" },
      { text: "Credits Added", value: "credits_to_be_added" },
      // { text: "Pack Selected", value: "pack_name" },
      // { text: "Paid via", value: "payment_gateway" },
      { text: "Transacted On", value: "transaction_on" },
      // { text: "Comments", value: "comment" },
      // { text: "Invoice", value: "s3_key" },
    ],
    TransactionsItems: [],
    search: "",
    fixed: true,
    height: 0,
    overlay: false,
    SnackBarComponent: {},
    // TransactionsStatus: "ALL",
    // PaidStatus: "ALL",
    // PaidItems: [],
    // TransactionsStatusItems: [],
    // TransactionsItemsNew: [],
    isLoading: false,
  }),
  mounted() {
    this.height = window.innerHeight - 190;
    this.get_transactions_list();
  },
  watch: {
    // PaidStatus(val) {
    //   if (val == "ALL") {
    //     this.TransactionsItems = this.TransactionsItemsNew.filter(
    //       (item) => item.pack_name == "PROMOTIONAL"
    //     );
    //   } else {
    //     this.TransactionsItems = this.TransactionsItemsNew.filter(
    //       (item) =>
    //         item.pack_name == "PROMOTIONAL" && item.payment_gateway == val
    //     );
    //   }
    // },
  },
  methods: {
    download_invoice(item) {
      window.open(item);
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async get_transactions_list() {
      this.TransactionsItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllTransactionsDetails, {
            input: {},
          })
        );
        // this.TransactionsItemsNew = JSON.parse(
        //   result.data.GetAllTransactionsDetails
        // ).Items.filter((val) => val.pack_name == "PROMOTIONAL");
        this.TransactionsItems = JSON.parse(
          result.data.GetAllTransactionsDetails
        ).Items.filter(
          (val) =>
            val.pack_name == "PROMOTIONAL" &&
            val.payment_gateway == "PROMOTIONAL"
        );
        // this.TransactionsStatusItems = this.TransactionsItems.filter(
        //   (val) => val.pack_name == "PROMOTIONAL"
        // );
        // this.PaidItems = this.TransactionsItems.filter(
        //   (val) => val.payment_gateway
        // );
        // // this.TransactionsStatusItems.unshift("ALL");
        // this.PaidItems.unshift("ALL");
        this.$forceUpdate();
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
